import { render, staticRenderFns } from "./Sopameet.vue?vue&type=template&id=b8fb96e4&scoped=true&"
import script from "./Sopameet.vue?vue&type=script&lang=js&"
export * from "./Sopameet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8fb96e4",
  null
  
)

export default component.exports